import React from "react";
import { toast } from "react-toastify";
import { FieldArray, useFormikContext } from "formik";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
} from "@material-ui/core";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { makeStyles } from "@material-ui/core/styles";

import { i18n } from "../../translate/i18n";

const useStyles = makeStyles((theme) => ({
  root: { display: "flex", flexWrap: "wrap", },

  alternativeKeywordContainer: { display: "flex", justifyContent: "center", alignItems: "center" },

  actionButton: { cursor: "pointer", "&:hover": { color: theme.palette.primary.main, }, },

  btnWrapper: { position: "relative", },

  floatingButton: {
    transition: "transform 0.30s",
    "&:hover": { transform: "translateY(-5px)", },
  },
}));

const AutomaticMessagesKeywordsModal = ({ open, onClose, nonUniqueKeywordsArray, setNonUniqueKeywordsArray }) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const classes = useStyles();
  const { setFieldValue, values } = useFormikContext();



  //  ***************
  //  ** Functions **
  //  ***************
  const handleClose = () => {
    onClose();

    setTimeout(() => { setNonUniqueKeywordsArray([]); }, 100);
  };

  const validateKeywords = (values) => {
    let areValuesValid = true;

    //  ******************
    //  ** Main Keyword **
    //  ******************
    values.mainKeyword = values.mainKeyword.trim();

    if (values.mainKeyword === "" || values.mainKeyword.length === 0) {
      areValuesValid = false;
      toast.info(i18n.t("automaticMessagesKeywordsModal.validations.mainKeyword"));
    }

    //  **************************
    //  ** Alternative Keywords **
    //  **************************
    let alternativeKeywordsFilteredArray = values.alternativeKeywords
        .filter(alternativeKeyword => !["", values.mainKeyword].includes(alternativeKeyword.trim()))
        .map(alternativeKeyword => alternativeKeyword.trim());

    alternativeKeywordsFilteredArray = Array.from(new Set(alternativeKeywordsFilteredArray));

    setFieldValue("alternativeKeywords", alternativeKeywordsFilteredArray);



    //  ************
    //  ** Return **
    //  ************
    return areValuesValid;
  };

  const handleSaveKeywords = () => {
    validateKeywords(values) && handleClose();
  };



  //  ************
  //  ** Return **
  //  ************
  return (
    <div className={classes.root}>
      <Dialog open={open} maxWidth="xs" scroll="paper" fullWidth>
        <DialogTitle>
          {i18n.t("automaticMessagesKeywordsModal.title")}
        </DialogTitle>

        <DialogContent dividers>
          {/* 
            *************************
            ** Duplicated Keywords **
            *************************
          */}
          {nonUniqueKeywordsArray.length > 0 && (
            <>
              <span>
                {i18n.t("automaticMessagesKeywordsModal.duplicatedKeywords")}
                &nbsp;
                {nonUniqueKeywordsArray.join(", ")}
              </span>
              
              <br />
              <br />
              <Divider />
              <br />
            </>
          )}

          {/* 
            *******************
            ** Main Keywords **
            *******************
          */}
          <TextField
            label={i18n.t("automaticMessagesKeywordsModal.form.mainKeyword")}
            variant="outlined"
            margin="dense"
            autoFocus
            fullWidth
            required

            value={values.mainKeyword}
            onChange={(event) => setFieldValue("mainKeyword", event.target.value.toLowerCase())}
          />

          {/* 
            **************************
            ** Alternative Keywords **
            **************************
          */}
          <FieldArray>
            {({ push, remove }) => (
              <>
                {values.alternativeKeywords
                 && values.alternativeKeywords.length > 0
                 && values.alternativeKeywords.map((alternativeKeyword, index) => (
                  <div key={`${index}-alternativeKeyword`} className={classes.alternativeKeywordContainer}>
                    <TextField
                      label={i18n.t("automaticMessagesKeywordsModal.form.alternativeKeyword")}
                      variant="outlined"
                      margin="dense"
                      autoFocus
                      fullWidth

                      value={values.alternativeKeywords[index]}
                      onChange={(event) => setFieldValue(`alternativeKeywords[${index}]`, event.target.value.toLowerCase())}
                    />

                    <DeleteOutlineIcon
                      size="small"
                      className={`${classes.actionButton} ${classes.floatingButton}`}
                      onClick={() => {
                        values.alternativeKeywords.splice(index, 1);
                        setFieldValue("alternativeKeywords", values.alternativeKeywords );
                      }}
                    />
                  </div>
                 ))}

                <div className={classes.fieldContainer1}>
                  <Button
                    style={{ flex: 1, marginTop: 8, }}
                    variant="outlined"
                    color="inherit"
                    className={classes.floatingButton}
                    onClick={() => {
                      values.alternativeKeywords.push("");
                      setFieldValue("alternativeKeywords", values.alternativeKeywords);
                    }}
                  >
                    {`+ ${i18n.t("automaticMessagesKeywordsModal.buttons.addAlternativeKeyword")}`}
                  </Button>
                </div>
              </>
            )}
          </FieldArray>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={handleSaveKeywords}
            color="primary"
            variant="contained"
            className={`${classes.btnWrapper} ${classes.floatingButton}`}
          >
            {i18n.t("automaticMessagesKeywordsModal.buttons.confirm")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AutomaticMessagesKeywordsModal;
